<template>
  <p class="description-price">
    <span v-for="(des, index) in filteredDes" :key="index">
      {{ `${des.key} ${des.value}` }}
    </span>
  </p>
</template>

<script>
export default {
  props: {
    description: {
      type: [String]
    }
  },
  data() {
    return {
      desPrice: [],
      des: {}
    };
  },
  computed: {
    totalPrice() {
      return this.desPrice.reduce((total, num) => {
        return total + +num.value;
      }, 0);
    },

    filteredDes() {
      return this.desPrice.filter(des => des.value);
    }
  },
  methods: {
    setFeatureData() {
      this.desPrice = [];
      let des_0 = this.description.replace("\n\n", ":").split("},");
      let des_1 = des_0[0].split("%:");
      let des_2 = `'${des_1[1]}}}'`;
      let des_3 = des_2.split('":{');
      let des_4 = des_3[1].split("}}'");
      let des_5 = `{${des_4[0]}}`;
      this.des = JSON.parse(des_5);

      for (const [key, value] of Object.entries(this.des)) {
        console.log(key, value);
        this.desPrice.push({
          key,
          value
        });
      }
    }
  },
  mounted() {
    this.setFeatureData();
  }
};
</script>
